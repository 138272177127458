import React from "react"
import Layout from "../components/layouts/Layout.js"
import { graphql, useStaticQuery } from "gatsby"
import { RichText } from "prismic-reactjs"
import GatsbyLink from "../components/GatsbyLink"
import Button from "../components/Button.js"
import * as styles from "../stylesheets/pages/product-links.module.scss"

const ProductLinks = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicRecProducts {
        data {
          banner {
            alt
            url
          }
          product {
            product_description {
              richText
            }
            product_image {
              alt
              url
            }
            product_link {
              url
            }
            product_name {
              text
            }
          }
        }
      }
    }
  `).prismicRecProducts.data

  const product = data.product.map((product, idx, array) => {
    const redirectToProduct = () => {
      window.location.href = product.product_link.url
    }

    return (
      <div key={idx} className={styles.product} onClick={redirectToProduct}>
        <div className={styles.productImageContainer}>
          <img
            src={product.product_image.url}
            alt={product.product_image.alt}
            className={styles.productImage}
          />
        </div>
        <div className={styles.productBodyContainer}>
          <h2 className={styles.productName}>{product.product_name.text}</h2>
          <div>
            <RichText
              render={product.product_description.richText}
              serializeHyperlink={GatsbyLink}
            />
          </div>
          {idx < array.length - 1 && <div className={styles.productDivider} />}
        </div>
      </div>
    )
  })

  return (
    <Layout
      title="Recommended Products - TechCollective"
      description="TechCollective is a technology consulting firm that specializes in helping small businesses with their technology needs."
    >
      <div className={styles.banner}>
        <img
          src={data.banner.url}
          alt={data.banner.alt}
          className={styles.bannerImage}
        />
        <div className={styles.bannerText}>
          <h1 className={styles.bannerTitle}>Recommended Products</h1>
        </div>
      </div>
      <div className={styles.bodyContainer}>
        <div className={styles.productsContainer}>{product}</div>
      </div>
    </Layout>
  )
}

export default ProductLinks
